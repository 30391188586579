$ = jQuery;

var $log = function(msg) {
        if (App.debug) {
            if (arguments.length > 1) {
                var to_console = [];
                for (i = 1; i < arguments.length; i++) {
                    to_console[i - 1] = arguments[i][0];
                }
                console.log(msg, to_console);
            } else {
                console.log(msg);
            }
        }
    },
    App = {
        debug: true,
        init: function() {

            this.scripts();
            this.sliders();

        },
        loaded: function() {},
        scripts: function() {

            if (App.is_touch_device()) {
                $("body").addClass("touch");
            } else {
                $("body").addClass("no-touch");
            }

            var $win = $(window),
                $body = $("body"),
                scrollPos = $win.scrollTop(),
                didScroll,
                lastScrollTop = 0,
                delta = 5,
                headerHeight = $('.page-header').outerHeight();

            $win.scroll(function(event) {
                didScroll = true;
            });

            setInterval(function() {
                if (didScroll) {
                    hasScrolled();
                    didScroll = false;
                }
            }, 250);

            function hasScrolled() {

                var st = $(this).scrollTop();

                if (Math.abs(lastScrollTop - st) <= delta)
                    return;

                if (st > lastScrollTop && st > 1) {
                    $('.page-header').removeClass('nav-down').addClass('nav-up');
                } else {
                    if (st + $win.height() < $(document).height()) {
                        $('header').removeClass('nav-up').addClass('nav-down');
                    }
                }

                lastScrollTop = st;
            }

            $win.on('scroll', function() {

                if ($(this).scrollTop() >= 1) {
                    $body.addClass('page-scrolled');
                } else {
                    $body.removeClass('page-scrolled');
                }

                scrollPos = $win.scrollTop();

            });

            if ($win.scrollTop() >= 1) {
                $body.addClass('page-scrolled');
            } else {
                $body.removeClass('page-scrolled');
            }

            // To top
            $(".back-top").on("click", function() {
                $("html,body").animate({
                    scrollTop: 0
                }, 550);
            });

            // Content link
            $(".content-link").click(function(e) {
                if ($(this).attr('data-slug').length && (typeof $(this).data('slug') !== 'undefined')) {
                    e.preventDefault();
                    var id = $(this).attr('data-slug');
                    $('html,body').animate({
                        scrollTop: $('#' + id).offset().top - 50
                    }, 550);
                }
            });

            // Accordion
            $(".collapsable").toggleClass("collapsed");
            //$('.collapsable-content').hide();
            var page_header = $(".page-header");


            $(".collapsable-header").click(function() {
                var header = $(this),
                    collapsable = header.parent(),
                    animation_options = {
                        duration: 'medium',
                        queue: false,
                        complete: function() {
                            header.trigger('scrolltoposition');
                        }
                    };;

                $(".collapsable.opened").not(collapsable).toggleClass("opened").toggleClass("collapsed").find('> .collapsable-content').slideToggle(animation_options);
                collapsable.toggleClass("opened").find('> .collapsable-content').slideToggle(animation_options)

            }).on('scrolltoposition', function() {
                $('html,body').animate({
                    scrollTop: $(this).parent().offset().top - page_header.outerHeight(true)
                }, 550);
            });

            // Tabs

            $('.tabs a').first().addClass('active');
            $('.tabs-content-wrapper').each(function() {
                $('.tab-content', this).first().show();
            });

            $('.tabs').on('click', 'a', function(e) {
                e.preventDefault();
                var data_id = $(this).data('id');

                $('.tabs a').removeClass('active').filter('[data-id="' + data_id + '"]').addClass('active');
                $('.tabs-content-wrapper .tab-content').hide().filter('[data-id="' + data_id + '"]').show();

            });

            var copy_buttons = $(".btn.copy-btn");

            if (copy_buttons.length) {
                copy_buttons.on("click", function(event) {
                    event.preventDefault();
                    App.copy_to_clipboard($(this).attr("href"));
                })
            }
        },

        sliders: function() {

            var carousel_slider = $('.carousel-slider .swiper-container');

            if (carousel_slider.length) {
                carousel_slider.each(function() {

                    var $this = $(this),
                        slides_count = '',
                        centered_slider = false;

                    if ($('.swiper-wrapper', $this).hasClass('slides-one')) {
                        slides_count = 1;
                        centered_slider = true;
                    } else if ($('.swiper-wrapper', $this).hasClass('slides-two')) {
                        slides_count = 2;
                    } else if ($('.swiper-wrapper', $this).hasClass('slides-three')) {
                        slides_count = 3;
                    } else if ($('.swiper-wrapper', $this).hasClass('slides-four')) {
                        slides_count = 4;
                    } else if ($('.swiper-wrapper', $this).hasClass('slides-five')) {
                        slides_count = 5;
                    } else if ($('.swiper-wrapper', $this).hasClass('slides-auto')) {
                        slides_count = 'auto';
                    }

                    $this.data("swiper", new Swiper($this, {
                        loop: false,
                        effect: 'slide',
                        centeredSlides: centered_slider,
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 700,
                        grabCursor: false,
                        //autoplay: 6000,
                        navigation: {
                            nextEl: $this.parent().find(".swiper-button-next")[0],
                            prevEl: $this.parent().find(".swiper-button-prev")[0],
                        },
                        breakpoints: {
                            480: {
                                slidesPerView: 2,
                            },
                            // when window width is >= 640px
                            640: {
                                slidesPerView: slides_count
                            }
                        }
                    }));

                });

            }

        },

        mobMenu: function(menuWrapper) {

            $this = menuWrapper;

            /* toggle main menu nav */
            $(".menu-btn", $this).on("click", function() {

                menuWrapper.toggleClass("opened");
                $("body").toggleClass("opened");

            });

            $(window).on("keyup", function(event) {

                if ($("body").hasClass("opened")) {
                    switch (event.keyCode) {
                        case 27: //esc
                            menuWrapper.removeClass("opened");
                            $("body").removeClass("opened");
                            break;
                    }
                }

            });

        },
        is_touch_device: function() {
            return (("ontouchstart" in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
        },
        copy_to_clipboard: function(element) {
            var $temp = $("<input>");
            $("body").append($temp);
            $temp.val(element).select();
            document.execCommand("copy");
            $temp.remove();

        }

    };

$(document).ready(function() {
    App.init();
    App.mobMenu($(".page-header"));
});